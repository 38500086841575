import React from 'react';
import ContentWrapper from './Wrapper/ContentWrapper';
import { Button, Typography } from '@mui/material';

const RatingThankYou = () => {
	return (
		<ContentWrapper maxWidth={'md'}>
			<Typography variant="h1" style={{ textAlign: 'center' }}>
				Děkujeme za hodnocení úklidu.
			</Typography>
			<div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
				<Typography className="pill" style={{ fontFamily: 'agrandir', fontSize: 18 }}>
					<span style={{ position: 'relative', top: 2 }}>Věděli jste že čteme každé hodnocení?</span>
				</Typography>
			</div>
			<div className="center">
				<Button
					style={{
						backgroundColor: '#8C5CBC',
						borderColor: '#8C5CBC',
						borderRadius: '400px',
						fontFamily: 'agrandir',
						textTransform: 'initial',
						fontSize: 20,
						padding: '10px 34px',
						boxShadow: 'none',
						marginTop: 40,
					}}
					variant="contained"
					onClick={() => (window.location = '/profil')}
				>
					<span style={{ position: 'relative', top: '2px' }}>Profil</span>
				</Button>
			</div>
		</ContentWrapper>
	);
};

export default RatingThankYou;
