import React from 'react';
import PropTypes from 'prop-types';
import '../styles/CheckBoxField.css';

const CheckBoxField = ({ label, checked, setChecked, disabled }) => {
	return (
		<label className="checkbox-field">
			<input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} disabled={disabled} />
			<span className="checkbox-label">{label}</span>
		</label>
	);
};

CheckBoxField.propTypes = {
	label: PropTypes.string.isRequired, // Text label next to the checkbox
	checked: PropTypes.bool, // Whether the checkbox is checked
	setChecked: PropTypes.func, // Callback for when the checkbox value changes
	disabled: PropTypes.bool, // Whether the checkbox is disabled
};

CheckBoxField.defaultProps = {
	checked: false,
	setChecked: () => {},
	disabled: false,
};

export default CheckBoxField;
