import { useState, useEffect } from 'react';

// A shared event name for clearing states
const CLEAR_EVENT = 'clearPersistentStates';

function usePersistentState(key, initialValue, prefix = 'persistent_state_') {
	const fullKey = `${prefix}${key}`;
	const [state, setState] = useState(() => {
		const savedValue = localStorage.getItem(fullKey);
		return savedValue !== null ? JSON.parse(savedValue) : initialValue;
	});

	// Update localStorage when the state changes
	useEffect(() => {
		localStorage.setItem(fullKey, JSON.stringify(state));
	}, [fullKey, state]);

	// Listen for the clear event to reset the state
	useEffect(() => {
		const handleClear = (event) => {
			if (event.detail && event.detail.prefix === prefix) {
				setState(initialValue); // Reset state to the initial value
			}
		};

		window.addEventListener(CLEAR_EVENT, handleClear);
		return () => {
			window.removeEventListener(CLEAR_EVENT, handleClear);
		};
	}, [prefix, initialValue]);

	return [state, setState];
}

export function clearPersistentStates(prefix = 'persistent_state_') {
	Object.keys(localStorage).forEach((key) => {
		if (key.startsWith(prefix)) {
			localStorage.removeItem(key);
		}
	});

	// Dispatch an event to notify all hooks to reset their states
	window.dispatchEvent(new CustomEvent('clearPersistentStates', { detail: { prefix } }));
}

export default usePersistentState;
