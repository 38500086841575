import { createTheme } from '@mui/material';

export const desktopTheme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004D58',
		},
	},
	typography: {
		display: 'block',
		fontFamily: 'agrandir, montserrat, Arial',
		fontSize: 16,
		color: '#004D58',
		body0: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 20,
			color: '#004D58',
		},
		body1: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 16,
			color: '#004D58',
		},
		body2: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 14,
			color: '#004D58',
		},
		h1: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 64,
			color: '#004D58',
			textAlign: 'left',
		},
		h2: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 48,
			color: '#004D58',
			textAlign: 'left',
		},
		h3: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 24,
			color: '#004D58',
			textAlign: 'left',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '30px',
				},
			},
		},
	},
});

export const mobileTheme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004D58',
		},
	},
	typography: {
		display: 'block',
		fontFamily: 'agrandir, montserrat, Arial',
		fontSize: 14,
		color: '#004D58',
		body0: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 18,
			color: '#004D58',
		},
		body1: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 14,
			color: '#004D58',
		},
		body2: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 12,
			color: '#004D58',
		},
		h1: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 36,
			color: '#004D58',
			textAlign: 'center',
		},
		h2: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 28,
			color: '#004D58',
			textAlign: 'center',
		},
		h3: {
			fontFamily: 'agrandir, montserrat, Arial',
			fontSize: 18,
			color: '#004D58',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '30px',
				},
			},
		},
	},
});
