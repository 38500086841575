import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import useIsMobile from './lib/Responsive';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton = ({ link = null }) => {
	const navigate = useNavigate();
	const back = () => {
		if (link === null) navigate(-1);
		else navigate(link);
	};
	const isMobile = useIsMobile();

	return (
		<>
			<Button
				style={{
					whiteSpace: 'nowrap',
					borderRadius: 19,
					boxShadow: 'none',
					background: '#004D58',
					textTransform: 'initial',
					fontFamily: 'agrandir',
				}}
				variant="contained"
				onClick={back}
			>
				<span style={{ position: 'relative', top: 2 }}>
					<ArrowBackIosIcon fontSize="20px" />
					Zpět
				</span>
			</Button>
			<div style={{ height: isMobile ? 10 : 24 }} />
		</>
	);
};

export default BackButton;
