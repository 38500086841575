import React from 'react';
import NumberCircle from '../NumberCircle';
import { Star } from '../Process';

const lang = {
	cta: 'Podat přihlášku',
	menu: {
		hr_email: 'kariera@youklid.cz',
		menu_items: [
			{
				title: 'Zaměstnání',
				url: '/prace',
			},
		],
		customer_link: 'Pro zákazníky',
	},
	hero: {
		locations: 'Praha / Brno / Plzeň / Liberec',
		heading: 'Nabíráme nové hospodyně',
		text: (
			<>
				Nabízíme práci v <strong> úklidu domácností </strong> s odměnou <strong> 170-230 Kč </strong> za hodinu (bonusy níže) v Praze, Brně, Plzni,
				Liberci a okolí.
			</>
		),
	},
	intro: {
		stats: [
			{ number: '4 města', text: 'Praha / Brno / Plzeň / Liberec' },
			{ number: '14', text: 'Let zkušeností' },
			{ number: '40+', text: 'Hospodyní' },
			{ number: '40 000+', text: 'Hotových úklidů' },
		],
		card: {
			heading: 'Proč se připojit k Youklidu?',
			text: (
				<>
					Zvolit si spolupráci s Youklidem znamená spojit síly s firmou, která si <strong>váží svých kolegů</strong>.
					<div style={{ height: 10 }} />
					Nabízíme <strong>legální práci</strong> se všemi jistotami, které z toho plynou, abyste jste se cítili spokojení.
					<div style={{ height: 10 }} />
					Můžete u nás dostávat <strong>odměnu až jednou týdně</strong>, což vám zajistí stálý a spolehlivý příjem.
					<div style={{ height: 10 }} />
					Naše flexibilní smlouvy vám umožní pracovat podle vašich možností a <strong>přizpůsobit se vašemu rozvrhu</strong>.
				</>
			),
		},
		values: [
			'Zakládáme si na otevřenosti a pravdě.',
			'Řídíme se skutečností, ne našimi pocity.',
			'Jsme profesionální a spolehliví.',
			'Kvalita je pro nás důležitější než růst.',
			'Uznáváme férové a morální hodnoty.',
		],
	},
	benefits: {
		pills_1: [
			{ color: '#a1e7d7', text: 'Legální práce' },
			{ color: '#fbfdfc', text: 'Smluvní flexibilita' },
			{ color: '#fbfdfc', text: 'Bez cestovních výdajů' },
			{ color: '#a1e7d7', text: '170-230 Kč' },
			{ color: '#fbfdfc', text: 'Přehled o práci a výdělcích' },
			{ color: '#fbfdfc', text: 'Důvěra' },
			{ color: '#a1e7d7', text: 'Nepotřebujete žádné vybavení' },
		],
		pills_2: [
			{ color: '#a1e7d7', text: 'Samostatnost' },
			{ color: '#fbfdfc', text: 'Bonusy za věrnost' },
			{ color: '#fbfdfc', text: 'Profesionalita' },
			{ color: '#a1e7d7', text: 'Výdělek jednou týdně' },
			{ color: '#fbfdfc', text: 'Péče' },
			{ color: '#fbfdfc', text: 'Kontrola pracovní zátěže' },
			{ color: '#a1e7d7', text: 'Vízové a právní poradenství' },
		],
	},
	job_description: {
		heading_expectations: 'Co od vás očekáváme',
		heading_offers: 'Co můžete čekat vy',
		expectations: [
			<>
				Nevadí vám <strong>cestovat po městě</strong> a okolí.
			</>,
			<>
				Máte <strong>chytrý telefon s připojením</strong> k internetu.
			</>,
			<>
				Máte <strong>čistý trestní rejstřík</strong> a pracovní povolení v České republice.
			</>,
			<>
				Máte <strong>dobré vystupování</strong> a záleží vám na morálních hodnotách.
			</>,
			<>
				Je pro vás <strong>důležitá kvalita</strong>, spolehlivost a dobře vykonaná práce.
			</>,
			<>
				Základní hovorová znalost <strong>českého jazyka</strong>.
			</>,
		],
		offers: [
			<>Základní odměna 170-230 Kč.</>,
			<>Můžete si zvolit rozvrh podle vlastní potřeby.</>,
			<>Možnost pracovní smlouvy, dohody, nebo spolupráce na živnost.</>,
			<>Práci začínáte cestou z vlastního domova.</>,
			<>Na úklidy nenosíte žádné vybavení.</>,
			<>Neustálý přehled o výdělcích a zakázkách v aplikaci.</>,
		],
	},
	process: {
		heading: 'Jaké jsou další kroky?',
		steps: [
			{
				number: <NumberCircle margin={false} number={1} />,
				heading: 'Přihlaste se',
				text: 'Zanechte nám na sebe kontakt ve formuláři níže.',
			},
			{
				number: <NumberCircle margin={false} number={2} />,
				heading: 'Přijďte k nám',
				text: 'Představíme vám Youklid a možnosti spolupráce.',
			},
			{
				number: <NumberCircle margin={false} number={3} />,
				heading: 'Školení',
				text: 'Zašleme vám do emailu školící materiály a zkušební test.',
			},
			{
				number: <NumberCircle margin={false} number={4} />,
				heading: 'Praktická zkouška',
				text: 'Vyzkoušíte si úklid u prvního klienta nanečisto.',
			},
			{
				number: <Star />,
				heading: 'Podpis smlouvy',
				text: 'Vytvoříme smlouvu a můžete začít ihned pracovat.',
			},
		],
	},
	testimonials: {
		heading: 'Co říkají naše hospodyně',
		more: 'Zobrazit více...',
		people: [
			{
				text: 'V Youklidu pracuju už dva roky a vždy jsem byla spokojená s komunikací s provozem. Když mám jakýkoliv problém nebo potřebuji změnit rozvrh, vždy se dohodneme. Navíc je super, že můžu jezdit rovnou z domova na zakázky, což mi šetří čas a nervy. Velkým bonusem je i mobilní aplikace, která je jednoduchá na ovládání a usnadňuje mi práci.',
				name: '— Maria',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: 'Jsem spokojená s výší odměn, které fakturuji. Peníze mi chodí pravidelně každý týden, což mi vyhovuje. Zároveň oceňuji férové jednání vedení a kvalitu klientů, pro které firma pracuje, včetně možnosti mít i vlastní klienty. Všechno funguje spolehlivě, hladce, a to mi dodává klid.',
				name: '— Olena',
				image: '/img/testimonials_4.jpg',
				image_position: 'top center',
			},
			{
				text: 'Tím že mám druhou práci, je pro mě dobré, že si mohu přizpůsobit rozvrh podle svých potřeb. Kdykoliv potřebuji více volného času, jde to zařídit. Taky mě potěšilo, že Youklid má profesionální přístup a vše probíhá hladce, včetně požadavků od klientů, kterých jsem se nejvíce bála.',
				name: '— Maiia',
				image: '/img/testimonials_5.jpg',
				image_position: 'top center',
			},
			{
				text: 'Co se mi na této firmě líbí nejvíce, je možnost určovat si rozvrh podle sebe. Jsem máma a mít možnost nastavit si rozvrh i na střídání týdnů mi pomáhá skloubit práci s rodinou. Navíc nemusím jezdit nikam daleko, většina klientů je v mém okolí, což mi ušetří spoustu času. Oceňuji, že nemusím nosit žádné vybavení, všechno je už na místě.',
				name: '— Natalia',
				image: '/img/testimonials_3.jpg',
				image_position: 'top center',
			},
			{
				text: 'Hledala jsem práci, která mi umožní měnit úvazek dle potřeby a zároveň nabídne slušnou odměnu. Tato firma mi dala obojí. Kromě toho si cením, že klienti jsou blízko mého bydliště a já nemusím trávit hodiny na cestách. Youklid je spolehlivý a jsou féroví, což je pro mě důležité.',
				name: '— Jana',
				image: '/img/testimonials_2.jpg',
				image_position: 'top center',
			},
		],
	},
	application: {
		heading: 'Naše aplikace je jako pračka, pracuje sama na pár kliknutí.',
		text: (
			<>
				Úklidy s námi jsou jednoduché. Naučíme vás používat naší aplikaci a pak už uvidíte všechny své objednávky přehledně přímo v ní. V&nbsp;aplikaci
				také získáváte detailní přehledy o úklidech.
			</>
		),
		pills: ['Přehled o práci a výdělcích', 'Nastavení rozvrhu', 'Rozpis úklidů', 'Podpora'],
		mockup: '/img/hiring_iphone_cs.png',
		alt: 'Youklid aplikace na iPhone',
	},
	form: {
		heading: 'Domluvte si pohovor.',
		text: 'Jste připraveni na novou práci? Jako v každé jiné práci vše začíná vstupním pohovorem, který si můžete ve formuláři s námi rovnou naplánovat. Pokud termín ještě neznáte, zanechte nám na sebe alespoň kontakt a zvolte možnost „Prosím, ozvěte se mi“. Obratem se s vámi spojíme a naplánujeme ho společně.',
		steps: ['Zanechte nám na sebe kontakt', 'Naplánujte si pohovor', 'Nebo vás kontaktujeme my'],
		contact_text: <>Na pohovoru vás přivítá Jana Cipenjuk,<br/> můžete jí volat s dotazy na telefonní číslo </>,
		contact_phone_link: (
			<a href="tel:+420608681228" style={{ textDecoration: 'underline' }}>
				+420{'\u00A0'}608{'\u00A0'}681{'\u00A0'}228
			</a>
		),
		contact_photo_url: 'https://assets.youklid.cz/jana_cipenjuk.jpg',
		name: 'Jméno a příjmení',
		phone: 'Telefon',
		email: 'Email',
		source: 'Odkud o nás víte?',
		location: 'Ve kterém městě si přejete pracovat?',
		more: 'Další města brzy...',
		variant: 'Přeji si mít pohovor',
		virtual: 'Telefonicky',
		face_to_face: 'V kanceláři',
		call_me: 'Prosím, ozvěte se mi',
		choose_virtual: 'Vyberte termín virtuálního pohovoru',
		choose_face_to_face: 'Vyberte termín pohovoru v kanceláři',
		no_slots_virtual: (
			<>
				Bohužel v tento moment nemáme žádné volné termíny na virtuální pohovor. To ovšem neznamená že si na vás nenajdeme čas. Prosím napište na{' '}
				<strong>
					{' '}
					<a href={'mailto:kariera@youklid.cz?subject=Nejsou volné termíny na virtuální pohovor'}>kariera@youklid.cz</a>{' '}
				</strong>{' '}
				a my vám obratem dáme termín.
			</>
		),
		no_slots_face_to_face: (
			<>
				Bohužel v tento moment nemáme žádné volné termíny na pohovor v kanceláři. To ovšem neznamená že si na vás nenajdeme čas. Prosím napište na{' '}
				<strong>
					{' '}
					<a href={'mailto:kariera@youklid.cz?subject=Nejsou volné termíny na pohovor v kanceláři'}>kariera@youklid.cz</a>{' '}
				</strong>{' '}
				a my vám obratem dáme termín.
			</>
		),
		submit: 'Rezervovat termín',
		success: 'Skvěle! Vaše přihláška už k nám dorazila. Budeme se na vás těšit.',
	},
	faq: {
		heading: 'Časté dotazy',
		list: [
			{
				question: 'Jak rychle mohu do práce nastoupit?',
				answer: 'Každý týden se na nás obrátí spoustu nových zákazníků. Nástup do práce je možný ihned po dokončení nejdůležitějších školení - přibližně 3 dny od kontaktu po podpis smlouvy.',
			},
			{
				question: 'Jaké druhy smluv a spolupráce nabízíte?',
				answer: 'U zaměstnanců nabízíme smlouvy typu DPP (dohoda o provedení práce), HPP (hlavní pracovní poměr). V případě spolupráce jako partner (máte vlastní zakázky a chcete s námi spolupracovat na dalších), je možné fakturovat na živnostenský list.',
			},
			{
				question: 'Jak vysoká bude moje skutečná měsíční odměna?',
				answer: (
					<>
						Odměna se liší podle druhu zvolené smlouvy, slev na daních a odpracovaných hodin. Pohybuje se v rozmezí od 150,- Kč (smlouvy DPP/HPP bez
						slev na dani) až po 200,- Kč (spolupráce jako partner na IČO) po odečtení zákonných odvodů. Čistou mzdu nebo výši odvodů u partnerů lze
						spočítat například na stránkách{' '}
						<a style={{ textDecoration: 'underline' }} href="https://www.kurzy.cz/kalkulacka/vypocet-ciste-mzdy/">
							Kurzy.cz
						</a>
						.
					</>
				),
			},
			{
				question: 'Jak často probíhá výplata odměn?',
				answer: 'U smluv DPP/HPP probíhá jednou měsíčně, vždy do 15. dne za měsíc předchozí po uzavření ve mzdové kanceláři. U partnerů spolupracujících na IČO je možná fakturace každý týden, s týdenní splatností.',
			},
			{
				question: 'Je možné na zakázky jezdit pomocí hromadné dopravy?',
				answer: 'Ano, všechny oblasti kde uklízíme jsou v dobré dostupnosti veřejné dopravy a podle toho si vybíráme oblasti, kde pracujeme.',
			},
			{
				question: 'Pracujete o víkendech a svátcích?',
				answer: 'Během víkendů běžně neuklízíme, státní svátky dle společné dohody a nastavení rozvrhu.',
			},
			{
				question: 'Musím si ke klientům brát úklidové vybavení?',
				answer: 'Běžně není potřeba ke klientům úklidové prostředky vozit. Výjimkou je část prvních úklidů, kde se klienti rozhodli zakoupit náš balíček prostředků (přibližně velikosti krabice od bot). K těmto klientům je možné balíčky které vám přivezeme za příplatek zavážet.',
			},
			{
				question: 'Jak probíhá zadávání práce?',
				answer: 'Po přijetí do naší společnosti dostanete přístup do aplikace, ve které vidíte plánované úklidy a se kterou vás naučíme pracovat. V aplikaci si můžete také kdykoliv zkontrolovat výplatu, nastavit směny nebo potvrzovat nově přidané úklidy.',
			},
		],
	},
	about_us: {
		heading: 'O Youklidu',
		text: (
			<>
				Jsme úklidová společnost se zkušenostmi <strong>více než 14&nbsp;let</strong> v oboru. Máme za sebou{' '}
				<strong>přes 40&nbsp;000 uskutečněných úklidů</strong> a v našem týmu nyní pracuje <strong>více než 40&nbsp;kolegů</strong>, kteří se starají o
				čisté domácnosti v Praze, Brně, Plzni, Liberci a jejich okolí.
				<div style={{ height: 10 }} />
				Naše společnost spolupracuje za legálních podmínek a nabízí stabilní práci v oboru.
			</>
		),
		more: 'Zjistit více',
	},
};

export default lang;
