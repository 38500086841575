import React, { useEffect, useState } from 'react';
import { Alert, Button, Chip, Grid, TextField, ThemeProvider, Typography } from '@mui/material';
import ContentWrapper from './Wrapper/ContentWrapper';
import { useNavigate, useParams } from 'react-router';
import useApiKey from './hooks/useApiKey';
import LayoutCard from './Components/LayoutCard';
import useIsMobile from './lib/Responsive';
import BackButton from './BackButton';
import { desktopTheme, mobileTheme } from './Recruitment/styles/themes';
import CheckBoxField from './Components/CheckBoxField';

const OrderRating = () => {
	const apiKey = useApiKey();
	let { order_id } = useParams();
	const navigate = useNavigate();
	const isMobile = useIsMobile();

	const [stars, setStars] = useState(null);
	const [text, setText] = useState('');
	const [discreet, setDiscreet] = useState(false);
	const [changeEmployee, setChangeEmployee] = useState(false);
	const [complaint, setComplaint] = useState(false);

	const [error, setError] = useState(null);

	const [review, setReview] = useState(null);

	const [good, setGood] = useState([
		{ label: 'Včasný příchod', value: 'arrival_ok', checked: false },
		{ label: 'Pečlivý úklid', value: 'detailed_cleaning', checked: false },
		{ label: 'Příjemná komunikace', value: 'pleasant_communication', checked: false },
		{ label: 'Prefesionální přístup', value: 'professional', checked: false },
	]);

	const [bad, setBad] = useState([
		{ label: 'Nedostatečné informace', value: 'insufficient_information', checked: false },
		{ label: 'Chybný přístup', value: 'wrong_access', checked: false },
		{ label: 'Špatné přístupové právo', value: 'wrong_access', checked: false },
		{ label: 'Zastaralá služba', value: 'outdated_service', checked: false },
	]);

	useEffect(() => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', apiKey);

		const requestOptions = {
			method: 'GET',
			headers: headers,
		};

		fetch(`${process.env.REACT_APP_API_URL}/v1/reviews?order_id=${order_id}`, requestOptions)
			.then((response) => response.json().then((data) => ({ status: response.status, ok: response.ok, data })))
			.then(({ status, ok, data }) => {
				if (ok) {
					setReview(data.review);
				} else {
					setReview(null);
					if (status !== 404) {
						setError(Object.values(data.errors).flat().join('<br />'));
					}
				}
			});
	}, []);

	const sendRating = () => {
		setError(null);
		if (![1, 2, 3, 4].includes(stars)) {
			setError('Musíte zvolit počet hvězdiček.');
			return;
		}

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', apiKey);

		const body = JSON.stringify({
			rating: stars,
			text: text,
			complaint: complaint,
			discreet: discreet,
			change_employee: changeEmployee,
			review_tags: JSON.stringify([...good, ...bad].filter((item) => item.checked).map((item) => item.value)),
			order_id: parseInt(order_id),
		});

		const requestOptions = {
			method: 'POST',
			headers: headers,
			body: body,
		};

		fetch(process.env.REACT_APP_API_URL + '/v1/reviews', requestOptions)
			.then((response) => response.json().then((data) => ({ status: response.status, ok: response.ok, data })))
			.then(({ status, ok, data }) => {
				if (ok) {
					navigate('/dekujeme-za-hodnoceni');
				} else {
					setError(Object.values(data.errors).flat().join('<br />'));
				}
			})
			.catch((error) => {
				setError('Něco se pokazilo: ' + error);
			});
	};

	return (
		<ContentWrapper showCTA={false} maxWidth={'md'}>
			<ThemeProvider theme={isMobile ? mobileTheme : desktopTheme}>
				<BackButton link={'/profil'} />
				<div style={{ display: 'grid', gridTemplateColumns: '1fr', justifyItems: 'start', gridGap: 40 }}>
					{error !== null && <Alert severity="error">{error}</Alert>}
					<LayoutCard>
						<Typography variant="h3" gutterBottom>
							Hodnocení úklidu
						</Typography>
						<Typography variant="body1">
							Najděte si chvilku a zanechte nám hodnocení.
							<div style={{ height: 5 }} />
							Snažíme se naše služby neustále zlepšovat, proto si každý den čteme všechna hodnocení.
							<div style={{ height: 5 }} />
							Nebojte se nám také napsat konkrétně co můžeme do příště zlepšit.
							<div style={{ height: 15 }} />
						</Typography>
						{review !== null && <ExistingReview review={review} />}
						{review === null && (
							<div style={{ display: 'grid', gridGap: 20 }}>
								<RatingPicker setStars={setStars} stars={stars} />
								<div>
									<RatingText setText={setText} text={text} />
									<div style={{ margin: isMobile ? '10px 0 0 0' : '10px 0 0 20px' }}>
										<CheckBoxField label={'Nepřeji si odesílat mé hodnocení hospodyni'} checked={discreet} setChecked={setDiscreet} />
									</div>
								</div>
								{/* <div>
									<Typography variant="body1" gutterBottom>
										Pochvaly
									</Typography>
									<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
										{good.map((item) => (
											<Chip
												key={item.label}
												label={item.label}
												variant="contained"
												color={item.checked ? 'primary' : undefined}
												onClick={() => {
													item.checked = !item.checked;
													setGood(good.map((item) => ({ ...item })));
												}}
											/>
										))}
									</div>
								</div>
								<div>
									<Typography variant="body1" gutterBottom>
										Výtky
									</Typography>
									<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
										{bad.map((item) => (
											<Chip
												key={item.label}
												label={item.label}
												variant="contained"
												color={item.checked ? 'primary' : undefined}
												onClick={() => {
													item.checked = !item.checked;
													setGood(good.map((item) => ({ ...item })));
												}}
											/>
										))}
									</div>
								</div>
								{stars && stars < 4 && (
									<div>
										<div style={{ margin: '10px 0 10px 0' }}>
											<CheckBoxField
												label={'Příště mi pošlete jinou hospodyni.'}
												checked={changeEmployee}
												setChecked={setChangeEmployee}
											/>
										</div>
										<Alert severity="info">
											They floated in the puppet place had been a subunit of Freeside’s security system. They floated in the dark, curled
											in his.
										</Alert>
									</div>
								)}
								{stars && stars < 3 && (
									<div>
										<div style={{ margin: '10px 0 10px 0' }}>
											<CheckBoxField label={'Přeji si podat reklamaci.'} checked={complaint} setChecked={setComplaint} />
										</div>
										<Alert severity="info">
											They floated in the puppet place had been a subunit of Freeside’s security system. They floated in the dark, curled
											in his.
										</Alert>
									</div>
								)} */}
								<SendButton sendRating={sendRating} CTA={'Dokončit hodnocení'} />
							</div>
						)}
					</LayoutCard>
				</div>
			</ThemeProvider>
		</ContentWrapper>
	);
};

const RatingPicker = ({ setStars, stars }) => {
	const isMobile = useIsMobile();

	const ratingEmojis = ['/img/rating_1.svg', '/img/rating_2.svg', '/img/rating_3.svg', '/img/rating_4.svg'];
	const ratingMeanings = ['Katastrofa', 'Mohlo být lepší', 'Dobrý', 'Fantastický'];

	return (
		<Grid container justifyContent={'center'} direction={'row'}>
			{[4, 3, 2, 1].map((item) => (
				<Grid item direction={'column'} justifyContent={'center'} alignItems={'center'} style={{ margin: isMobile ? '0 5px' : '10px 20px' }}>
					<Button
						onClick={() => setStars(item)}
						key={item}
						style={{
							background: stars === item ? '#f2f2f2' : 'initial',
						}}
					>
						<img src={ratingEmojis[item - 1]} alt="" style={{ width: isMobile ? '40px' : 'initial' }} />
					</Button>
					<Typography variant="body1" style={{ textAlign: 'center', fontSize: isMobile ? '0.6em' : 'initial' }}>
						{ratingMeanings[item - 1]}
					</Typography>
				</Grid>
			))}
		</Grid>
	);
};

const RatingText = ({ setText, text }) => {
	const isMobile = useIsMobile();
	return (
		<TextField
			label="Co byste nám ještě chtěli sdělit? (nepovinné)"
			variant="outlined"
			className="w-full"
			onChange={(e) => {
				setText(e.target.value);
			}}
			value={text}
			multiline
			InputLabelProps={{
				style: {
					color: 'rgba(0, 66, 80, 0.5)',
				},
			}}
		/>
	);
};

const SendButton = ({ sendRating, CTA = 'Odeslat hodnocení' }) => {
	return (
		<Grid container justifyContent={'center'}>
			<Button
				style={{
					backgroundColor: '#8C5CBC',
					borderColor: '#8C5CBC',
					borderRadius: '400px',
					fontFamily: 'agrandir',
					textTransform: 'initial',
					fontSize: 16,
					padding: '8px 24px',
					boxShadow: 'none',
					marginTop: 20,
				}}
				variant="contained"
				onClick={sendRating}
			>
				<span style={{ position: 'relative', top: '2px' }}> {CTA} </span>
			</Button>
		</Grid>
	);
};

const ExistingReview = ({ review }) => {
	const ratingEmojis = ['/img/rating_1.svg', '/img/rating_2.svg', '/img/rating_3.svg', '/img/rating_4.svg'];
	const ratingMeanings = ['Katastrofa', 'Mohlo být lepší', 'Dobrý', 'Fantastický'];

	return (
		<div>
			<div style={{ height: 40 }} />
			<Typography>
				<strong>Hodnotili jste objednávku</strong>
			</Typography>
			<Typography>
				{review.review_rating}/4 - {ratingMeanings[review.review_rating - 1]}
			</Typography>
			<div style={{ height: 10 }} />
			<Typography>
				<strong>Vaše zpráva</strong> (<i>{review.discreet ? 'Nezobrazuje se hospodyni' : 'Zobrazuje se hospodyni'}</i>)
			</Typography>
			<Typography> {review.review_text}</Typography>
			{review.change_employee == true && (
				<>
					<div style={{ height: 10 }} />
					<Typography>
						<strong>Přejete si změnit hospodyni</strong>
					</Typography>
					<Typography> Ano </Typography>
				</>
			)}
			{review.change_employee == true && (
				<>
					<div style={{ height: 10 }} />
					<Typography>
						<strong>Žádáte o jinou hospodyni</strong>
					</Typography>
					<Typography> Ano </Typography>
				</>
			)}
			{review.complaint == true && (
				<>
					<div style={{ height: 10 }} />
					<Typography>
						<strong>Žádáte o reklamaci</strong>
					</Typography>
					<Typography> Ano </Typography>
				</>
			)}
		</div>
	);
};

export default OrderRating;
