import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersistentState from './usePersistentState';

const useApiKey = () => {
	const navigate = useNavigate();
	const [apiKey, setApiKey] = usePersistentState('api_key', '', 'user_data_');

	useEffect(() => {
		// Check if there's already an apiKey in the persistent state
		if (apiKey) return;

		// Get uid and token from localStorage
		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');

		// If either uid or token are missing, sign out and redirect to login
		if (!uid || !token) {
			localStorage.removeItem('uid');
			localStorage.removeItem('token');
			setApiKey('');
			navigate('/prihlaseni');
			return;
		}

		// Generate API key by calling your backend API
		const url = `${process.env.REACT_APP_API_URL}/api-token/${uid}/create?token=${token}`;

		// Assuming you have a function `fetchApiKey` to fetch the API key
		fetchApiKey(url)
			.then((data) => {
				if (data.apiKey) {
					setApiKey(data.apiKey); // Save API key to persistent state
				} else {
					console.error('Failed to generate API key');
				}
			})
			.catch((error) => {
				console.error('Error fetching API key:', error);
			});
	}, [apiKey, setApiKey]);

	return apiKey;
};

async function fetchApiKey(url) {
	const response = await fetch(url, { method: 'POST' });
	if (!response.ok) {
		throw new Error('Failed to fetch API key');
	}
	return response.json();
}

export default useApiKey;
