import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import LandingPageHTML from "./LandingPageHTML";
import Send from "./Send";
import NotSend from "./NotSend";
import Profile from "./Profile";
import reportWebVitals from "./reportWebVitals";
import Login from "./Login";
import Page404 from "./Page404";
import Paid from "./Paid";
import FrontPage from "./FrontPage";
import GotoEmail from "./GotoEmail";
import AccountSettings from "./AccountSettings";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import PaymentOptions from "./PaymentOptions";
import Capacities from "./Capacities";
import Invoices from "./Invoices";
import CreditManagement from "./CreditManagement";
import AddCredit from "./AddCredit";
import PlanningForm from "./PlanningForm";
import LeadForm from "./LeadForm";
import CapacitiesNew from "./CapacitiesNew";
import EmailRouting from "./EmailRouting";
import CustomPayment from "./CustomPayment";
import OrderRating from "./OrderRating";
import MenuTest from "./MenuTest";
import VOP from "./SidePages/VOP.js";
import CompanyInfo from "./SidePages/CompanyInfo";
import GDPR from "./SidePages/GDPR";
import EthicsCodex from "./SidePages/EthicsCodex";
import AboutUs from "./SidePages/AboutUs";
import AvailableCities from "./SidePages/AvailableCities";
import AvailableCapacities from "./SidePages/AvailableCapacities";
import Vouchers from "./SidePages/Vouchers";
import Media from "./SidePages/Media";
import FAQ from "./SidePages/FAQ";
import Services from "./SidePages/Services";
import ExtraServices from "./SidePages/ExtraServices";
import Products from "./SidePages/Products";
import Prices from "./SidePages/Prices";
import FirstOrderHelp from "./SidePages/FirstOrderHelp";
import OldPeopleHelp from "./SidePages/OldPeopleHelp";
import PriceTest from "./PriceTest";
import RecruitmentLandingPage from "./Recruitment/index.jsx";
import RecruitmentThankYouPage from './Recruitment/ThankYou.jsx';
import ChooseAppointment from "./ChooseAppointment";
import AppointmentThankYouPage from "./AppointmentThankYouPage";
import VouchersForm from "./SidePages/VouchersForm";
import VouchersGenerator from "./SidePages/VouchersGenerator";
import ApplyVoucher from "./ApplyVoucher.js";
import RequestAffiliate from "./RequestAffiliate.js";
import TicketForm from "./TicketForm.js";
import NewFrontpage from "./NewFrontpage.js";
import Support from "./SidePages/Support.js";
import LeadFormV3 from "./LeadFormV3.js";
import LeadSavePage from "./SidePages/LeadSavePage.js";
import ExpansionVoting from "./SidePages/ExpansionVoting.js";
import AboutUsHR from "./SidePages/AboutUsHR.js";
import Badges from "./Badges";
import RatingThankYou from "./RatingThankYou.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#004250",
    },
    secondary: {
      main: "#8C5CBC",
    },
  },
  shape: {
    borderRadius: 19, // defaults to 4
  },
});



Sentry.init({
  dsn: "https://7cc5b1b728bf4c1ab40a50a2160de3eb@o1400792.ingest.sentry.io/6746481",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});





ReactDOM.render(
  (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<FrontPage />} />
            {/* Order process */}
            <Route exact path="/odeslano" element={<Send />} />
            <Route exact path="/platba" element={<PaymentOptions />} />
            <Route exact path="/zaplatit" element={<CustomPayment />} />
            <Route exact path="/chyba" element={<NotSend />} />
            <Route exact path="/zaplaceno/:order_id" element={<Paid />} />
            {/* Profile */}
            <Route exact path="/profil" element={<Profile />} />
            <Route exact path="/hodnoceni/:order_id" element={<OrderRating />} />
            <Route exact path="/dekujeme-za-hodnoceni" element={<RatingThankYou />} />
            <Route exact path="/objednavka" element={<App />} />            {/* TODO */}
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/prihlaseni" element={<Login />} />
            <Route exact path="/ucet" element={<AccountSettings />} />
            <Route exact path="/doklady" element={<Invoices />} />
            <Route exact path="/faktury" element={<Invoices />} />
            <Route exact path="/kredit" element={<CreditManagement />} />
            <Route exact path="/nabit-kredit/platba" element={<AddCredit origin="platba" />} />
            <Route exact path="/nabit-kredit" element={<AddCredit origin="nabiti" />} />
            <Route exact path="/uplatnit-voucher" element={<ApplyVoucher />} />
            <Route exact path="/affiliate" element={<RequestAffiliate />} />
            <Route exact path="/oceneni" element={<Badges />} />
            <Route exact path="/emaily" element={<GotoEmail />} />
            <Route exact path="/sign_in_token/:uid/:token" element={<Profile />} />
            <Route exact path="/r/:route" element={<EmailRouting />} />
            {/* Side pages */}
            <Route exact path="/co-delat-kdyz-u-me-neuklizite" element={<LeadSavePage />} />
            <Route exact path="/hlasovani-o-expanzi-youklidu" element={<ExpansionVoting />} />
            <Route exact path="/vseobecne-obchodni-podminky" element={<VOP />} />
            <Route exact path="/ochrana-osobnich-udaju" element={<GDPR />} />
            <Route exact path="/zakladni-informace" element={<CompanyInfo />} />
            <Route exact path="/kontakt" element={<CompanyInfo />} />
            <Route exact path="/eticky-kodex" element={<EthicsCodex />} />
            <Route exact path="/o-nas" element={<AboutUs />} />
            <Route exact path="/kdo-jsme-a-co-chceme" element={<AboutUs />} />
            <Route exact path="/o-youklidu" element={<AboutUsHR />} />
            <Route exact path="/media" element={<Media />} />
            <Route exact path="/poukazky" element={<Vouchers />} />
            <Route exact path="/poukazky/nakup" element={<VouchersForm />} />
            <Route exact path="/poukazky/stazeni/:code" element={<VouchersGenerator />} />
            <Route exact path="/dostupne-lokality" element={<AvailableCities />} />
            <Route exact path="/dostupne-kapacity" element={<AvailableCapacities />} />
            <Route exact path="/sluzby" element={<Services />} />
            <Route exact path="/doplnkove-sluzby" element={<ExtraServices />} />
            <Route exact path="/produkty" element={<Products />} />
            <Route exact path="/reference" element={<Prices />} />
            <Route exact path="/cenik" element={<Prices />} />
            <Route exact path="/casto-kladene-otazky" element={<FAQ />} />
            <Route exact path="/faq" element={<FAQ />} />
            <Route exact path="/podpora" element={<Support />} />
            <Route exact path="/prvni-uklid" element={<FirstOrderHelp />} />
            <Route exact path="/pomoc" element={<OldPeopleHelp />} />            {/* Pomoc pro důchodce */}
            <Route exact path="/prace" element={<RecruitmentLandingPage />} />
            <Route exact path="/kariera" element={<RecruitmentLandingPage />} />
            <Route exact path="/nabor" element={<RecruitmentLandingPage />} />
            <Route exact path="/brigada" element={<RecruitmentLandingPage />} />
            <Route exact path="/dekujeme-za-zajem" element={<RecruitmentThankYouPage />} />
            {/* Dev */}
            <Route exact path="/tickets" element={<TicketForm category_code={'C32'} text_template={'Přeji si zrušit objednávku na DD.MM.YYYY. Děkuji.'} />} />
            <Route exact path="/kapacity" element={<CapacitiesNew />} />
            <Route exact path="/frontpage" element={<NewFrontpage />} />
            {/* <Route exact path="/kapacity" element={<Capacities />} /> */}
            <Route exact path="/kapacity/nove" element={<CapacitiesNew />} />
            <Route exact path="/menu-test" element={<MenuTest />} />
            <Route exact path="/price-test" element={<PriceTest />} />
            <Route exact path="/planovani" element={<PlanningForm />} />           {/* Not working */}
            <Route exact path="/schuzka/rezervovano" element={<AppointmentThankYouPage />} />
            <Route exact path="/schuzka/:type" element={<ChooseAppointment contact_label={'Jméno a příjmení'} />} />
            <Route exact path="/leads" element={<LeadFormV3 />} />
            {/* Error pages */}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  ),
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
