import React, { useEffect, useState } from 'react';
import ContentWrapper from './Wrapper/ContentWrapper';
import { Button, CircularProgress, Grid, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';
import Page404 from './Page404';
import './Recruitment/styles/Form.css';
import useIsMobile from './lib/Responsive';
import { desktopTheme, mobileTheme } from './Recruitment/styles/themes';
import { GenericHiringButton } from './Recruitment/HiringButton';

function ChooseAppointment(props) {
	const isMobile = useIsMobile();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const urlName = searchParams.get('name');
	const urlEmail = searchParams.get('email');
	const urlState = searchParams.get('state');
	const urlLocation = searchParams.get('location');

	// candidate data
	const [name, setName] = useState(urlName);
	const [email, setEmail] = useState(urlEmail);
	const [slotId, setSlotId] = useState(null);

	const [slots, setSlots] = useState([]);
	const [days, setDays] = useState([]);
	const [loading, setLoading] = useState(true);
	const { type } = useParams();
	const navigate = useNavigate();

	const type_to_variant_id = {
		pohovor: 1,
		'podpis-smlouvy': 2,
		'prakticka-zkouska': 3,
		'online-pohovor': 4,
		'prodlouzeni-smlouvy': 5,
		schuzka: 6,
	};

	const variant_id = type_to_variant_id[type];

	const appointment_variant_names = {
		1: 'pohovor',
		2: 'podpis smlouvy',
		3: 'praktickou zkoušku',
		4: 'online pohovor',
		5: 'prodloužení smlouvy',
		6: 'schůzku',
	};

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/slots?variant_id=${variant_id}`)
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setSlots(data.slots);

					const groupedAppointments = data.slots
						.filter((slot) => {
							return slot.location == null || (slot.location ?? '').toLowerCase().includes((urlLocation ?? '').toLowerCase());
						})
						.reduce((acc, appointment) => {
							const date = appointment.appointment_slot_begin.split(' ')[0];
							if (!acc[date]) {
								acc[date] = [];
							}
							acc[date].push(appointment);
							return acc;
						}, {});

					// Sort the grouped appointments by day
					const sortedGroupedAppointments = Object.keys(groupedAppointments)
						.sort()
						.map((acc, key) => {
							let result = {};
							result.date = acc;
							result.appointments = groupedAppointments[acc];
							return result;
						}, {});
					setDays(sortedGroupedAppointments);
					// console.log(sortedGroupedAppointments);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
				setLoading(false);
			});
	}, [variant_id]);

	const sendReservation = () => {
		fetch(process.env.REACT_APP_API_URL + '/reserve-quick', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				slot_id: slotId,
				name: name,
				email: email,
				state: urlState,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					navigate('/schuzka/rezervovano');
				} else {
					navigate('/schuzka/chyba');
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	if (!type_to_variant_id.hasOwnProperty(type)) {
		return <Page404 />;
	}

	return (
		<ContentWrapper maxWidth={'md'}>
			<ThemeProvider theme={isMobile ? mobileTheme : desktopTheme}>
				<div className="form-wrapper" style={{ background: 'unset', padding: 0 }}>
					<div className="form">
						<div className="card" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
							<div className="text">
								<Typography variant="h2" className="width-300">
									Domluvte si {appointment_variant_names[variant_id]}.
								</Typography>
								<Typography component="div" style={{ marginTop: '20px' }}>
									Přivítá vás Jana Cipenjuk, můžete jí volat s dotazy na telefonní číslo{' '}
									<a href="tel:+420608681228" style={{ textDecoration: 'underline' }}>
										+420{'\u00A0'}608{'\u00A0'}681{'\u00A0'}228
									</a>
								</Typography>
								<img className="contact-photo" src="https://assets.youklid.cz/jana_cipenjuk.jpg" alt={''} />
							</div>
							<div className="details">
								<TextField
									// className="colspan-2"
									size="small"
									name="name"
									disabled={urlName != null}
									label={
										<>
											Jméno a příjmení <span style={{ color: 'red' }}>*</span>
										</>
									}
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
								<TextField
									// className="colspan-2"
									size="small"
									name="email"
									disabled={urlEmail != null}
									label={
										<>
											Email <span style={{ color: 'red' }}>*</span>
										</>
									}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<div className="section-wrapper colspan-2">
									<div className="appointments">
										{loading && (
											<Grid container justifyContent={'center'} style={{ margin: '40px 0' }}>
												<Grid item>
													<CircularProgress size="40px" style={{ color: '#004d58' }} />
												</Grid>
											</Grid>
										)}
										<div className="options">
											<div className="options-grid">
												{days.map((day, index) => (
													<div key={index}>
														<Typography className="date">{format_date(day.date)}</Typography>
														<Grid container gap={2}>
															{day.appointments.map((appointment, appointment_index) => (
																<Button
																	size="small"
																	color="secondary"
																	variant={slotId === appointment.appointment_slot_id ? 'contained' : 'outlined'}
																	key={appointment_index}
																	onClick={() => setSlotId(appointment.appointment_slot_id)}
																>
																	<span style={{ position: 'relative', top: 2 }}>
																		{new Date(appointment.appointment_slot_begin).toLocaleTimeString('cs-CZ', {
																			hour: 'numeric',
																			minute: 'numeric',
																		})}
																	</span>
																</Button>
															))}
														</Grid>
													</div>
												))}
											</div>
											{!loading && days.length <= 0 && (
												<Typography variant="body1" style={{ margin: 0 }}>
													Bohužel v tento moment nemáme žádné volné termíny na {appointment_variant_names[variant_id]}. To ovšem
													neznamená že si na vás nenajdeme čas. Prosím napište na{' '}
													<a
														style={{ textDecoration: 'underline' }}
														href={
															'mailto:kariera@youklid.cz?subject=Nejsou volné termíny na ' + appointment_variant_names[variant_id]
														}
													>
														kariera@youklid.cz
													</a>{' '}
													a my vám obratem dáme termín.
												</Typography>
											)}
										</div>
									</div>
								</div>
								{days.length > 0 && (
									<div style={{ display: 'flex', justifyContent: 'flex-end' }} className="colspan-2">
										<GenericHiringButton label={'Rezervovat termín'} onClick={sendReservation} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</ThemeProvider>
		</ContentWrapper>
	);
}

function format_date(date) {
	const formatted = new Date(date).toLocaleDateString('cs-CZ', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

	return formatted[0].toUpperCase() + formatted.slice(1);
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004D58',
		},
	},
	typography: {
		display: 'block',
		fontFamily: 'agrandir, Arial, Arial',
		fontSize: 14,
		color: '#004D58',
		body0: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 18,
			color: '#004D58',
		},
		body1: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 14,
			color: '#004D58',
		},
		body2: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 12,
			color: '#004D58',
		},
		h1: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 36,
			color: '#004D58',
			textAlign: 'center',
		},
		h2: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 28,
			color: '#004D58',
			textAlign: 'center',
		},
		h3: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 18,
			color: '#004D58',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

export default ChooseAppointment;
