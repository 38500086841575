import React, { useEffect, useState } from 'react';
import {
	Button,
	Grid,
	ThemeProvider,
	Typography,
	createTheme,
	Container,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	Box,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	TextField,
	Chip,
	Divider,
	Alert,
} from '@mui/material';
import FooterTest from '../FooterTest';
import Menu from './Menu';
import useIsMobile from '../lib/Responsive';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogSnag from 'logsnag';
import { useNavigate } from 'react-router';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Hero from './Hero';
import useLang from './langs/I18n';
import Introduction from './Introduction';
import Wave from './Wave';
import Benefits from './Benefits';
import JobDescription from './JobDescription';
import Process from './Process';
import Testimonials from './Testimonials';
import Application from './Application';
import Form from './Form';
import FAQ from './FAQ';
import AboutUs from './AboutUs';
import { desktopTheme, mobileTheme } from './styles/themes';

const sng = new LogSnag({
	token: 'ee66dc7f1d3ba4d39b98d7acac318360',
	project: 'hr',
});

function RecruitmentLandingPage(props) {
	const [lang, setLangState] = useState('cz');
	const t = useLang();
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [city, setCity] = useState(null);
	const [validateEmailOrPhone, setValidateEmailOrPhone] = useState(null);
	const isMobile = useIsMobile();
	const navigate = useNavigate();

	const setLang = lang => {
		setLangState(lang);
		localStorage.setItem('lang', lang);
	};

	const send = () => {
		const cityNames = [
			'Praha',
			'Brno',
			'Plzeň',
			'Liberec',
			'Hradec Králové',
			'Pardubice',
			'Olomouc',
			'České Budějovice',
			'Ostrava',
			'Karlovy Vary',
			'Zlín',
			'Jihlava',
			'Ústí nad Labem',
		];

		setValidateEmailOrPhone(phone != '' || email != '');

		if (phone == '' && email == '') return;

		const tel = '[instantní telefon](tel:' + phone.replace(/ /g, '%20') + ')';
		const mailto =
			'[instantní email]' +
			(
				'(mailto:kariera@youklid.cz?subject=Nový kandidát&body=Jméno: ' +
				name +
				'%0ATelefon: ' +
				phone +
				'%0AEmail: ' +
				email +
				'%0AMěsto: ' +
				cityNames[city] +
				'%0AJazyk: ' +
				lang +
				')'
			).replace(/ /g, '%20');

		async function sendApplication() {
			await sng.publish({
				project: 'hr',
				channel: 'applications',
				event: 'Nový kandidát',
				description: 'Použít ' + tel + ' nebo ' + mailto + '. Údaje: ' + name + '; ' + email + '; ' + phone + '; ' + cityNames[city] + '; ' + lang,
				icon: '💼',
				notify: true,
				tags: {
					city: cityNames[city],
				},
				parser: 'markdown',
			});

			navigate('/dekujeme-za-zajem');
		}
		sendApplication();
	};

	return (
		<>
			<Menu t={t} setLang={setLang} />
			<ThemeProvider theme={isMobile ? mobileTheme : desktopTheme}>
				<Hero t={t} />
				<Wave />
				<Introduction t={t} />
				<Benefits t={t} />
				<JobDescription t={t} />
				<Process t={t} />
				<Testimonials t={t} />
				<Application t={t} />
				<Form t={t} />
				<Wave />
				<FAQ t={t} />
				<AboutUs t={t} />
			</ThemeProvider>
			<FooterTest background={'#fff'} />
		</>
	);
}

function NumberCircle(number) {
	return (
		<div
			style={{
				background: '#004d58',
				color: '#fff',
				fontSize: 20,
				fontFamily: 'agrandir, montserrat, Arial',
				borderRadius: 50,
				padding: 4,
				width: 40,
				textAlign: 'center',
				display: 'inline-block',
				marginBottom: 8,
				marginRight: 16,
			}}
		>
			<span style={{ position: 'relative', top: 2 }}>{number}</span>
		</div>
	);
}

function NumberCircleMobile(number) {
	return (
		<Grid container justifyContent={'center'}>
			<div
				style={{
					background: '#004d58',
					color: '#fff',
					fontSize: 20,
					fontFamily: 'agrandir, montserrat, Arial',
					borderRadius: 50,
					padding: 4,
					width: 40,
					textAlign: 'center',
					//display: 'inline-block',
					marginBottom: 8,
					marginRight: 16,
				}}
			>
				<span style={{ position: 'relative', top: 2 }}>{number}</span>
			</div>
		</Grid>
	);
}

export default RecruitmentLandingPage;
